<template>
  <div class="home">
    <main>
      <router-view :key="$route.path"></router-view>
    </main>
    <nav>
      <router-link v-for="leiste in leisten" :key="leiste.id" :to="'/'+leiste.id" :style="menuWidth(leiste.id)" replace>{{leiste.name}}</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      leisten: [
        {
          id: 1,
          name: 'Fernseher'
        }, {
          id: 2,
          name: 'Küche'
        }, {
          id: 3,
          name: 'Potsdam'
        }, {
          id: 4,
          name: 'Bett'
        }
      ]
    }
  },

  methods: {
    menuWidth: function (id) {
      let style = ''
      let margin = 6
      if (this.leisten[0].id === id) {
        style += 'margin-left: 0;'
        margin -= 3
      }
      if (this.leisten[this.leisten.length - 1].id === id) {
        style += 'margin-right: 0;'
        margin -= 3
      }
      return style + 'width: calc(100vw / ' + this.leisten.length + ' - ' + margin + 'px);'
    }
  }
}
</script>

<style scoped>
nav {
  width: 100vw;
  height: 6vh;
}

nav a {
  float: left;
  height: 100%;
  margin: 0 3px;
  text-align: center;
  background: var(--darkBlue);
  color: var(--white);
  text-decoration: none;
  font-weight: bold;
  font-size: 3vh;
  vertical-align: center;
}

nav a.router-link-active {
  text-decoration: underline;
}
</style>
