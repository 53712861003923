export default {
  methods: {
    getFunctionsURL: function () {
      return 'https://led.sebastian-mitte.de/functions.php'
    },

    getDataURL: function () {
      return 'https://led.sebastian-mitte.de/getData.php'
    }
  }
}
