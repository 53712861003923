import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UebersichtView from '../views/UebersichtView.vue'
import StandortView from '../views/StandortView.vue'
import SteuerungView from '../views/SteuerungView.vue'
import LuftView from '../views/LuftView.vue'

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    children: [
      {
        path: '/',
        name: UebersichtView,
        component: UebersichtView
      },
      {
        path: '/:standortId',
        name: StandortView,
        component: StandortView,
        children: [
          {
            path: '/:standortId',
            name: SteuerungView,
            component: SteuerungView
          },
          {
            path: '/:standortId/luft',
            name: LuftView,
            component: LuftView
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
